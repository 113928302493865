<template>
    <b-card no-body class="mb-0">
        <!-- Filtrado -->
        <div class="m-2">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <label>Mostrar</label>
                    <v-select
                        class="per-page-selector d-inline-block mx-50"
                        v-model="perPage"
                        :options="pageOptions"
                        :clearable="false"
                    />
                    <label>registros</label>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-input-group class="mr-1">
                            <b-form-input
                                type="search"
                                placeholder="Buscar..."
                                v-model="filter"
                            />
                            <b-input-group-append>
                                <b-button
                                    class="p-0 px-1"
                                    variant="secondary"
                                    :disabled="!filter"
                                    @click="filter = ''"
                                >
                                    <feather-icon icon="XIcon" size="21" />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <b-button
                            v-if="$can('read', 'Movimientos')"
                            variant="primary"
                            :to="{ name: 'mov-captura-movimientos-agregar' }"
                        >
                            <span class="text-nowrap">Nuevo Movimiento</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
        <!-- Filtrado -->

        <b-table
            stacked="md"
            show-empty
            small
            class="position-relative"
            empty-text="No se encontraron registros"
            :items="data"
            :fields="columns"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
        >
            <template #cell(folio)="data">
                <div style="width: 100px" class="text-truncate">
                    <b class="text-primary">#{{ data.item.folio }}</b>
                </div>
            </template>

            <template #cell(fecha)="data">
                <div class="text-wrap">
                    <span class="align-text-top">{{
                        formatDate(data.item.fecha)
                    }}</span>
                </div>
            </template>

            <template #cell(empleado)="data">
                <b-media vertical-align="center">
                    <template #aside>
                        <b-avatar
                            size="30"
                            :text="avatarText(data.value.nombre_completo)"
                            :variant="`light-primary`"
                            :to="{
                                /*name: 'apps-users-view', params: { id: data.item.id } */
                            }"
                        />
                    </template>
                    <span
                        :to="{
                            /*name: 'apps-users-view', params: { id: data.item.id }*/
                        }"
                        :class="`text-primary`"
                        class="font-weight-bold d-block text-nowrap"
                        >{{ data.value.nombre_completo }}
                    </span>
                </b-media>
            </template>

            <template #cell(pago)="data">
                <div class="text-wrap">
                    <span
                        class="align-text-top font-weight-bold text-success"
                        >{{ formatMoney(data.item.pago) }}</span
                    >
                </div>
            </template>

            <template #cell(horas_trabajadas)="data">
                <div class="text-center font-weight-bold">
                    <span class="align-text-top text-dark">{{
                        data.item.horas_trabajadas
                    }}</span>
                </div>
            </template>

            <!-- Column: User -->
            <template #cell(centro_costo)="data">
                <small v-if="data.value.malla"
                    >Malla: {{ data.value.malla.nombre }} <br /><span
                        >{{ data.value.nombre }} (
                        {{ data.value.descripcion }})</span
                    >
                </small>
                <small v-else>
                    <button
                        class="btn btn-primary rounded-pill btn-sm"
                        @click="open_modal(data.item, 'centro_trabajo')"
                    >
                        <feather-icon
                            icon="LinkIcon"
                            size="16"
                            class="align-middle"
                        />
                        Asignar Centro de Trabajo
                    </button>
                </small>
            </template>
            <template #cell(hora_salida)="data">
                <div class="text-center">
                    <span v-if="data.item.hora_salida">
                        {{ data.item.hora_salida }}
                    </span>
                    <!-- <small v-else>
                        <button
                            class="btn btn-info btn-small rounded-pill btn-sm"
                        >
                            <feather-icon
                                icon="ArrowRightIcon"
                                size="16"
                                class="align-middle"
                            />
                            Registrar Salida
                        </button>
                    </small> -->
                </div>
            </template>

            <template #cell(acciones)="data" v-if="$can('read', 'Movimientos')">
                <div class="text-nowrap">
                    <b-dropdown variant="link" no-caret>
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item
                            @click="open_modal(data.item, 'Editar')"
                        >
                            <feather-icon
                                icon="EditIcon"
                                class="text-warning"
                            />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="desactivarMovimiento(data.item)"
                        >
                            <feather-icon
                                icon="TrashIcon"
                                class="text-danger"
                            />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>
        </b-table>

        <!-- Paginacion -->
        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted">
                        Mostrando {{ dataMeta.from }} de {{ dataMeta.to }} de
                        {{ dataMeta.of }} registros
                    </span>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                    <b-pagination
                        class="mb-0 mt-1 mt-sm-0"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
        <!-- Paginacion -->

        <b-modal
            v-model="modalShow"
            @hidden="
                modalShow = false;
                item_selected = null;
            "
            :no-close-on-backdrop="true"
            @ok="AsignarCentroTrabajo()"
        >
            <template #modal-title>
                <feather-icon
                    icon="LinkIcon"
                    size="18"
                    class="align-middle mr-25"
                />
                Asignar Centro de Trabajo
            </template>
            <template v-if="item_selected">
                <h4 class="text-primary mb-2">
                    <feather-icon
                        icon="UserIcon"
                        size="18"
                        class="align-middle mr-25"
                    />
                    {{ item_selected.empleado.nombre_completo.toUpperCase() }}
                </h4>
                <small class="text-dark mb-2 mr-2"
                    ><feather-icon
                        icon="CalendarIcon"
                        size="18"
                        class="align-middle mr-25 text-primary"
                    />
                    {{ formatDate(item_selected.fecha) }}</small
                >
                <small class="text-dark mb-2 mr-2"
                    >Entrada
                    <feather-icon
                        icon="ClockIcon"
                        size="18"
                        class="align-middle mr-25 text-success"
                    />
                    {{ item_selected.hora_entrada }}</small
                >
                <small class="text-dark mb-2 mr-2"
                    >Salida
                    <feather-icon
                        icon="ClockIcon"
                        size="18"
                        class="align-middle mr-25 text-danger"
                    />
                    <span v-if="item_selected.hora_salida">
                        {{ item_selected.hora_salida }}
                    </span>
                    <span v-else>Pendiente</span></small
                >
            </template>
            {{ /*item_selected*/ }}
            <div class="d-flex align-items-center mt-2">
                <span
                    class="title font-weight-bold"
                    style="width: 15% !important"
                >
                    <feather-icon icon="GridIcon" class="" size="16" /> Malla:
                </span>
                <div style="width: 85% !important">
                    <!-- Select Client -->
                    <v-select
                        v-model="malla"
                        :options="mallas"
                        @input="getCentrosTrabajo()"
                        label="Nombre"
                        :clearable="false"
                    ></v-select>
                </div>
            </div>
            <div class="d-flex align-items-center mt-2">
                <span
                    class="title font-weight-bold"
                    style="width: 15% !important"
                >
                    <feather-icon icon="LayersIcon" class="" size="16" /> Centro
                    de Trabajo:
                </span>
                <div style="width: 85% !important">
                    <!-- Select Client -->
                    <v-select
                        v-model="centro_trabajo"
                        :options="centros_trabajo"
                        label="Nombre"
                        input-id="invoice-data-client"
                        :clearable="false"
                    ></v-select>
                </div>
            </div>
            <!-- <pre>
					{{ centro_trabajo }}
				</pre> -->
        </b-modal>

        <b-modal
            v-model="modalShowEditar"
            @hidden="
                modalShowEditar = false;
                item_selected = null;
            "
            :no-close-on-backdrop="true"
            @ok="EditarMovimiento()"
        >
            <template #modal-title>
                <feather-icon
                    icon="LinkIcon"
                    size="18"
                    class="align-middle mr-25"
                />
                Editar Movimiento
            </template>
            <template v-if="item_selected">
                <h4 class="text-primary mb-2">
                    <feather-icon
                        icon="UserIcon"
                        size="18"
                        class="align-middle mr-25"
                    />
                    {{ item_selected.empleado.nombre_completo.toUpperCase() }}
                </h4>
                <small class="text-dark mb-2 mr-2"
                    ><feather-icon
                        icon="CalendarIcon"
                        size="18"
                        class="align-middle mr-25 text-primary"
                    />
                    {{ formatDate(item_selected.fecha) }}</small
                >
                <small class="text-dark mb-2 mr-2"
                    >Entrada
                    <feather-icon
                        icon="ClockIcon"
                        size="18"
                        class="align-middle mr-25 text-success"
                    />
                    {{ item_selected.hora_entrada }}</small
                >
                <small class="text-dark mb-2 mr-2"
                    >Salida
                    <feather-icon
                        icon="ClockIcon"
                        size="18"
                        class="align-middle mr-25 text-danger"
                    />
                    <span v-if="item_selected.hora_salida">
                        {{ item_selected.hora_salida }}
                    </span>
                    <span v-else>Pendiente</span></small
                >
            </template>
            <!-- {{ item_selected }} -->
            <div class="d-flex align-items-center mt-2" v-if="item_selected">
                <span
                    class="title font-weight-bold"
                    style="width: 30% !important"
                >
                    <feather-icon icon="GridIcon" class="" size="16" /> Malla:
                </span>
                <div style="width: 70% !important">
                    <!-- <v-select
                        v-if="item_selected.centro_costo"
                        v-model="item_selected.centro_costo.malla.id"
                        :options="mallas"
                        @input="getCentrosTrabajo()"
                        label="Nombre"
                        :clearable="false"
                    ></v-select> -->
                    <span
                        v-if="
                            item_selected.centro_costo &&
                            item_selected.centro_costo.malla
                        "
                    >
                        {{ item_selected.centro_costo.malla.nombre }}
                    </span>
                    <v-select
                        v-else
                        v-model="malla"
                        :options="mallas"
                        @input="getCentrosTrabajo()"
                        label="Nombre"
                        :clearable="false"
                    ></v-select>
                </div>
            </div>
            <div class="d-flex align-items-center mt-2" v-if="item_selected">
                <span
                    class="title font-weight-bold"
                    style="width: 30% !important"
                >
                    <feather-icon icon="LayersIcon" class="" size="16" /> Centro
                    Trabajo:
                </span>
                <div style="width: 70% !important">
                    <!-- <v-select
                        v-if="item_selected.centro_costo"
                        v-model="item_selected.centro_costo_id"
                        :options="centros_trabajo"
                        label="Nombre"
                        input-id="invoice-data-client"
                        :clearable="false"
                    ></v-select> -->
                    <span v-if="item_selected.centro_costo">
                        {{ item_selected.centro_costo.nombre }} (
                        {{ item_selected.centro_costo.descripcion }})
                    </span>
                    <v-select
                        v-else
                        v-model="item_selected.centro_costo_id"
                        :options="centros_trabajo"
                        label="Nombre"
                        input-id="invoice-data-client"
                        :clearable="false"
                    ></v-select>
                </div>
            </div>
            <div class="d-flex align-items-center mt-2" v-if="item_selected">
                <span
                    class="title font-weight-bold"
                    style="width: 30% !important"
                >
                    <feather-icon icon="ClockIcon" class="" size="16" /> Hora de
                    Entrada:
                </span>
                <div style="width: 70% !important">
                    <!-- Select Client -->
                    <b-form-input
                        type="time"
                        step="1"
                        v-model="item_selected.hora_entrada"
                    />
                </div>
            </div>
            <div class="d-flex align-items-center mt-2" v-if="item_selected">
                <span
                    class="title font-weight-bold"
                    style="width: 30% !important"
                >
                    <feather-icon icon="ClockIcon" class="" size="16" /> Hora de
                    Salida:
                </span>
                <div style="width: 70% !important">
                    <!-- Select Client -->
                    <b-form-input
                        @input="
                            calcularHorasTrabajadas(
                                item_selected.hora_entrada,
                                item_selected.hora_salida
                            )
                        "
                        type="time"
                        id="inputHoraSalida"
                        v-model="item_selected.hora_salida"
                    />
                </div>
            </div>
            <div class="d-flex align-items-center mt-2" v-if="item_selected">
                <span
                    class="title font-weight-bold"
                    style="width: 30% !important"
                >
                    <feather-icon icon="ClipboardIcon" class="" size="16" />
                    Horas Trabajadas:
                </span>
                <div style="width: 70% !important">
                    <!-- Select Client -->
                    <b-form-input
                        type="number"
                        v-model="item_selected.horas_trabajadas"
                    />
                </div>
            </div>
            <div class="d-flex align-items-center mt-2" v-if="item_selected">
                <span
                    class="title font-weight-bold"
                    style="width: 30% !important"
                >
                    <feather-icon icon="DollarSignIcon" class="" size="16" />
                    Pago:
                </span>
                <div style="width: 70% !important">
                    <!-- Select Client -->
                    <b-form-input type="number" v-model="item_selected.pago" />
                </div>
            </div>
            <!-- <pre>
					{{ item_selected }}
				</pre
            > -->
        </b-modal>
    </b-card>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormCheckbox,
    BMedia,
    BAvatar,
    BModal,
} from "bootstrap-vue";
import axios from "@axios";
import router from "@/router";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, alphaNum, email, numeric } from "@validations";
import vSelect from "vue-select";
import utilerias from "@/views/utilerias";
import moment from "moment";
import { avatarText } from "@core/utils/filter";

const { msgToast, msjConfirmarDesactivar } = utilerias();

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BForm,
        BFormGroup,
        BFormInput,
        BTable,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPopover,
        BInputGroup,
        BInputGroupAppend,
        BPagination,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        BFormSelect,
        BRow,
        BCol,
        BFormCheckbox,
        vSelect,
        BModal,
        ValidationProvider,
        ValidationObserver,
        BMedia,
        BAvatar,
    },
    setup() {
        return {
            avatarText,
        };
    },
    data() {
        return {
            malla: null,
            centro_trabajo: null,
            mallas: [],
            centros_trabajo: [],
            item_selected: null,
            modalShow: false,
            modalShowEditar: false,
            data: null,
            columns: [
                { key: "folio", label: "Folio", sortable: true },
                { key: "fecha", label: "Fecha", sortable: true },
                { key: "empleado", label: "Empleado", sortable: true },
                {
                    key: "centro_costo",
                    label: "Centro Trabajo",
                    sortable: true,
                },
                { key: "hora_entrada", label: "Entrada", sortable: true },
                { key: "hora_salida", label: "Salida", sortable: true },
                {
                    key: "horas_trabajadas",
                    label: "Horas Trabajadas",
                    sortable: true,
                },
                { key: "pago", label: "Pago", sortable: true },
                { key: "acciones" },
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 25, 50, 100],
            filter: null,
        };
    },
    computed: {
        dataMeta() {
            return {
                from: this.perPage * (this.currentPage - 1) + 1,
                to:
                    this.perPage * this.currentPage < this.totalRows
                        ? this.perPage * this.currentPage
                        : this.totalRows,
                of: this.totalRows,
            };
        },
    },
    async mounted() {
        await this.cargaMovimientos();
        (this.totalRows = this.data.length), this.getMallas();
    },
    methods: {
        open_modal(item, type) {
            this.malla = null;
            this.centro_trabajo = null;

            this.item_selected = item;
            if (type == "Editar") {
                this.modalShowEditar = true;
            } else {
                this.modalShow = true;
            }
        },
        EditarMovimiento() {
            if (this.item_selected) {
                let payload = {
                    hora_entrada: this.item_selected.hora_entrada,
                    hora_salida: this.item_selected.hora_salida,
                    horas_trabajadas: this.item_selected.horas_trabajadas,
                    pago: this.item_selected.pago,
                };

                if (this.item_selected.centro_costo_id != 0) {
                    payload.centro_costo_id =
                        this.item_selected.centro_costo_id.Codigo;
                }

                axios
                    .put("/api/movimientos/" + this.item_selected.id, payload)
                    .then((response) => {
                        msgToast.fire({
                            icon: "success",
                            title: "Movimiento Actualizado",
                        });
                        this.modalShowEditar = false;
                        this.cargaMovimientos();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                msgToast.fire({
                    icon: "error",
                    title: "Selecciona un movimoento a editar",
                });
            }
        },
        AsignarCentroTrabajo() {
            if (this.centro_trabajo) {
                let payload = {
                    centro_costo_id: this.centro_trabajo.Codigo,
                };
                axios
                    .put(
                        "/api/movimientos/updatecentrotrabajo/" +
                            this.item_selected.id,
                        payload
                    )
                    .then((response) => {
                        msgToast.fire({
                            icon: "success",
                            title: "Centro de Trabajo Asignado",
                        });
                        this.modalShow = false;
                        this.cargaMovimientos();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                msgToast.fire({
                    icon: "error",
                    title: "Selecciona un Centro de Trabajo",
                });
            }
        },
        getMallas() {
            axios.get("/api/cat-mallas").then((response) => {
                this.mallas = response.data.data;
            });
        },
        getCentrosTrabajo() {
            axios
                .get("/api/cat-centros-costo/by-malla/" + this.malla.Codigo)
                .then((response) => {
                    this.centros_trabajo = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onFiltered(filteredItems) {
            this.currentPage = 1;
            this.totalRows = filteredItems.length;
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        async cargaMovimientos() {
            const r = await axios.get(`/api/movimientos`);
            this.data = r.data.data.data;
        },
        desactivarMovimiento(item) {
            msjConfirmarDesactivar.fire({}).then(async (result) => {
                if (result.isConfirmed) {
                    const r = await axios.delete(
                        `/api/movimientos/${item.id}`,
                        { withCredentials: true }
                    );
                    msgToast.fire({
                        icon: "success",
                        title: "Movimiento desactivado",
                    });
                    await this.cargaMovimientos();
                }
            });
        },
        resolveMovimientoActivoVariant(status) {
            if (status === "Autorizado") return "success";
            if (status === "Cancelado") return "danger";
            if (status === "Rechazado") return "danger";
            return "secondary";
        },
        formatDate(date) {
            if (!date) return null;
            return moment(date).format("DD/MM/YYYY");
        },
        randomColor() {
            const colors = [
                "primary",
                "success",
                "danger",
                "warning",
                "info",
                "secondary",
            ];
            return colors[Math.floor(Math.random() * colors.length)];
        },
        formatMoney(value) {
            //format to mexican currency
            var formatter = new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 2,
            });

            return formatter.format(value);
        },
        calcularHorasTrabajadas(hora_entrada, hora_salida) {
            if (hora_salida < hora_entrada) {
                this.item_selected.hora_salida = null;
                let inputSalida = document.getElementById("inputHoraSalida");
                inputSalida.value = null;
                msgToast.fire({
                    icon: "error",
                    title: "La hora de salida no puede ser menor a la hora de entrada",
                });
            }
            if (hora_entrada && hora_salida) {
                let hora_entrada_split = hora_entrada.split(":");
                let hora_salida_split = hora_salida.split(":");
                let hora_entrada_date = new Date();
                let hora_salida_date = new Date();
                hora_entrada_date.setHours(
                    hora_entrada_split[0],
                    hora_entrada_split[1]
                );
                hora_salida_date.setHours(
                    hora_salida_split[0],
                    hora_salida_split[1]
                );
                let diff = hora_salida_date - hora_entrada_date;
                let hours = Math.floor(diff / 1000 / 60 / 60);
                diff -= hours * 1000 * 60 * 60;
                let minutes = Math.floor(diff / 1000 / 60);
                diff -= minutes * 1000 * 60;
                let seconds = Math.floor(diff / 1000);
                diff -= seconds * 1000;
                let milliseconds = diff;
                //let horas_trabajadas = hours + ":" + minutes;
                this.item_selected.horas_trabajadas = hours;
            }
        },
    },
};
</script>

<style>
.content-header .breadcrumb {
    display: flex !important;
}
</style>
